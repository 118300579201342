<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { ref, watch, onMounted } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { LicenseManager } from "ag-grid-enterprise";
import {
    saveGridState,
    loadGridState,
    connectToServerApi
} from "@/utils/agGridUtils";
import { getDefaultColDefs, getDefaultGridOptions } from '../../utils/aggridCommonConfigs.js';
import status from '../../components/schedule/statusRenderer.vue';
import { useThemeStore } from "@/stores/themeStore";
import ResetColumnComponent from "../../components/common/aggrid/ResetColumnComponent.vue";
import Warehouses from '../../components/common/Warehouses.vue';
import { shortDateWithTimeTz } from "@/utils/dateUtils.ts";
import EcommerceFilter from "./EcommerceFilter.vue";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const themeStore = useThemeStore();
const OUTBOUND_ECOMM_URL = "/ecommerce/outbound_orders";
const GRID_SAVE_KEY = "ecomm_orders_grid_state";
const ECOMM_EXTERNAL_FILTERS_KEY = "ecomm_filters";
const showFilters = ref(true);
const gridLoaded = ref(false);

let gridApi;
let context = ref(null);
let rowSelection = null;

onMounted(() => {
    let filters = JSON.parse(window.localStorage.getItem(ECOMM_EXTERNAL_FILTERS_KEY));
    showFilters.value = filters?.showFilters;
});

const onGridReady = (params) => {
    gridApi = params.api;
    context.value = {
        apiUrl: OUTBOUND_ECOMM_URL,
        filterKey: ECOMM_EXTERNAL_FILTERS_KEY,
        advancedFilterKeys: advancedFilterKeys
    };
    rowSelection = 'multiple';
    loadGridState(GRID_SAVE_KEY, gridApi, columnDefs);
    gridLoaded.value = true;
    gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

const onStateUpdated = function (params) {
    saveGridState(GRID_SAVE_KEY, gridApi);
};

const defaultColDef = getDefaultColDefs();

const advancedFilterKeys = ["id", "client.name", "po_number", "warehouse.name", "total_pieces", "carrier.name", "created_at", "order_status.name"];

const getStatusColor = (params) => {

    let color = "grey";

    let status = params.value;
    if (status === 'New') {
        color = "#395F5E";
    } else if (status === 'To Pick') {
        color = "#DC7633";
    } else if (status == 'Picking') {
        color = "#7393B3";
    } else if (status == 'Staged') {
        color = "#6C3483";
    } else if (status == 'Shipped') {
        color = "#8FBC8F";
    } else if (status == 'Delivered') {
        color = "green";
    } else if (status == 'Cancelled') {
        color = "#333";
    }
    return {
        color
    };
}

const columnDefs = [
    { field: "number", minWidth: 200, headerName: "Order #", colId: "id", cellDataType: "text", headerCheckboxSelection: true, checkboxSelection: true },
    { field: "client.name", minWidth: 250, headerName: "Client Name", colId: "client.name", cellDataType: "text", width: "300" },
    { field: "po_number", minWidth: 250, headerName: "PO #", colId: "po_number", cellDataType: "text", width: "300" },
    { field: "warehouse.name", minWidth: 200, headerName: "Warehouse", colId: "warehouse.name", width: "300" },
    { field: 'total_pieces', minWidth: 100, headerName: "Pcs", colId: "total_pieces", cellDataType: "text", width: "300" },
    { field: "carrier.name", minWidth: 150, headerName: "Carrier Service", colId: "carrier.name", cellDataType: "text" },
    {
        field: "created_at", minWidth: 210, headerName: "Order Timer", colId: "created_at", cellDataType: "text", valueGetter: (params) => {
            return shortDateWithTimeTz(params.data.created_at);
        }
    },
    {
        field: "order_status.name", minWidth: 150, headerName: "Status", colId: "order_status.name", cellDataType: "text",
        filter: 'agSetColumnFilter',
        width: "270",
        filterParams: {
            values: ["New", "To Pick", "Picking", "Staged", "Delivered", "Shipped", "Canceled"],
        },
        cellRenderer: status,
        cellRendererParams: getStatusColor,
    }
];

const rowDoubleClicked = function (event) {
    // navigate to outbound orders details
    // route.push(`/test/${event.data.id}`);
};

const gridOptions = getDefaultGridOptions(columnDefs);

watch(showFilters, (showFilters) => {
  saveFiltersToLocalStorage(showFilters);
});

const saveFiltersToLocalStorage = function () {
  let filters = JSON.parse(window.localStorage.getItem(ECOMM_EXTERNAL_FILTERS_KEY));
  if (!filters) filters = {};
  filters.showFilters = showFilters?.value;
  window.localStorage.setItem(ECOMM_EXTERNAL_FILTERS_KEY, JSON.stringify(filters));
};

</script>

<template>

    <Warehouses :callBack="() => gridApi.onFilterChanged()" :parent-filter="ECOMM_EXTERNAL_FILTERS_KEY" />
    <ResetColumnComponent :grid-save-key="GRID_SAVE_KEY" :grid-api="gridApi" />

    <div class="d-flex justify-space-between ml-2">
        <v-btn color="blue" size="small" variant="outlined" @click="showFilters = !showFilters"
            class="filter-btn ml-2 mt-1 mr-2" :append-icon="showFilters ? 'mdi-eye-off-outline' : 'mdi-eye-outline'">{{
        showFilters ? "Hide Filters" :
            "Show Filters" }}</v-btn>

    </div>
    <div class="pa-4 d-flex">
        <EcommerceFilter v-if="showFilters && gridLoaded" :grid-api="gridApi" class="mr-4" />
        <AgGridVue style="width: 100%; height: 800px;" :class="themeStore.agTheme" :columnDefs="columnDefs"
            :defaultColDef="defaultColDef" :gridOptions="gridOptions" @grid-ready="onGridReady" :context="context"
            @state-updated="onStateUpdated" :rowSelection="rowSelection" @rowDoubleClicked="rowDoubleClicked">
        </AgGridVue>
    </div>
</template>