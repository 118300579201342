<script setup>
import { onMounted } from "vue";
import { setPageTitle } from "../../utils/pageTitle";
import Ecommerce from "@/components/ecommerce/Ecommerce.vue";

onMounted(() => {
  setPageTitle("Fulfillment");
});

</script>

<template>
  <div id="ecomm-grid-wrapper">
    <Ecommerce />
  </div>
</template>

<style scoped>
#ecomm-grid-wrapper {
  height: 100%;
  padding-top: 0.25%;
}
</style>
