<script setup>

import { ref, onMounted } from "vue";
import debounce from "lodash/debounce";
import useCarriers from "@/hooks/useCarriers";
import { yearMonthDayFormat } from "@/utils/dateUtils";

const props = defineProps({
  gridApi: Object
});

const carrierName = ref(null);
const todaySelected = ref(false);
const { ecommerceCarriers, fetchEcommerceCarriers } = useCarriers();

const carrierUrlParams = {
  filter: {
    name: {
    type: "contains",
    filter: "",
    },
  },
  sort: [
    {
    sort: "asc",
    colId: "name",
    },
  ],
  limit: 500,
};

onMounted(async () => {

  await fetchEcommerceCarriers();

  const filterModelCarrier = props.gridApi.getColumnFilterModel('carrier.name');

  if (filterModelCarrier) {
    carrierName.value = filterModelCarrier.filter;
  }

  const filterModelStatus = props.gridApi.getColumnFilterModel('order_status.name');

  if (!filterModelStatus) {
    statusFilter.value.values.filter(val => val.label === 'All').map(val => val.active = true);
  } else {
    statusFilter.value.values.filter(val => val.value.length === 1 && val.value[0] === filterModelStatus.values[0]).map(val => val.active = true);
  }

  const filterModelTodayFilter = props.gridApi.getColumnFilterModel('created_at');
  console.log(filterModelTodayFilter, 'today filter');
  if (filterModelTodayFilter) {
    todaySelected.value = true;
  } else {
    todaySelected.value = false;
  }
});

const statusFilter = ref({

  filter: {
    field: "order_status.name",
  },
  values: [
    {
      label: "All",
      value: ["To Pick", "Picking", "New", "Delivered","Staged","Shipped","Canceled"],
      active: false
    },
    { label: "New", value: ["New"], active: false },
    { label: "To Pick", value: ["To Pick"], active: false },
    { label: "Picking", value: ["Picking"], active: false },
    { label: "Staged", value: ["Staged"], active: false },
    { label: "Shipped", value: ["Shipped"], active: false },
    { label: "Delivered", value: ["Delivered"], active: false },
    { label: "Canceled", value: ["Canceled"], active: false },
  ],
});

const applySetFilter = async (filter, values) => {
  await props.gridApi.setColumnFilterModel(filter.field, { values });
  props.gridApi.onFilterChanged();
};

const applyTextFilter = async (field, filter) => {

  await props.gridApi.setColumnFilterModel(field, {
    filterType: 'text',
    type: 'startsWith',
    filter: filter,
  });

  props.gridApi.onFilterChanged();
}

const applyDayFilter = async (field, filter) => {

  if (filter == 'today') {
    filter = yearMonthDayFormat(new Date(), '-');
    todaySelected.value = true;
  } else {
    filter = '';
    todaySelected.value = false;
  }

  await props.gridApi.setColumnFilterModel(field, {
    filterType: 'text',
    type: 'contains',
    filter: filter,
  });

  props.gridApi.onFilterChanged();
}

const debouncedApplyTextFilter = debounce(applyTextFilter, 500);
const debouncedApplySetFilter = debounce(applySetFilter, 500);

const applyStatusFilter = async (filter, values, label) => {

  await debouncedApplySetFilter(filter, values);

  statusFilter.value.values.map(status => status.active = false);

  statusFilter.value.values.filter(status => {
    return status.label === label;
  }).map(status => status.active = true);
}

const debouncedFetchCarriers = debounce(async (query) => {
  carrierUrlParams.filter.name.filter = query;
  await fetchEcommerceCarriers(carrierUrlParams);
}, 300);


</script>

<template>
  <div class="w-25">

    <v-card class="mx-auto pa-2 w-100">

      <v-autocomplete v-model="carrierName" density="compact" :items="ecommerceCarriers" label="Filter Carrier"
        @update:search="(query) => debouncedFetchCarriers(query)" 
        @update:model-value="debounce(() => debouncedApplyTextFilter('carrier.name', carrierName), 500)()" 
        item-title="name" item-value="name" hide-details
        variant="outlined">
      </v-autocomplete>
      <div class="d-flex justify-space-around py-4 border-t border-b my-2">
        <v-btn size="small" variant="tonal" :color="todaySelected ? '' : 'primary'" @click="applyDayFilter('created_at', 'all')">All</v-btn>
        <v-btn size="small" variant="tonal" :color="todaySelected ? 'primary' : ''" @click="applyDayFilter('created_at', 'today')">Today</v-btn>
      </div>
      <v-list v-if="statusFilter">

        <v-list-subheader>STATUS</v-list-subheader>

        <v-list-item v-for="(status, i) in statusFilter.values" :key="i" :value="status.value"
          @click="applyStatusFilter(statusFilter.filter, status.value, status.label)" color="primary" rounded="xl"
          :class="{ 'v-list-item--active': status.active === true }">
          <template v-slot:prepend>
            <v-icon>{{ status.active ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon>
          </template>

          <v-list-item-title v-text="status.label"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<style scoped>
.v-list-item--active {
  color: #007bea;
  background-color: #b4c7d6;
}
</style>